<template>
    <div class="relative">
        <!-- Not authenticated - Login button -->
        <template v-if="!authenticated">
            <button
                class="btn [v-cloak]:transform-none"
                :class="{
                    'scale-105 transform p-1.5 text-blue-demegro': isPinging,
                }"
                @click="loginModalVisible = true"
            >
                <font-awesome-icon :icon="['fas', 'user']" class="mr-2.5 text-base" />
                Inloggen
            </button>
        </template>

        <!-- Authenticated - User menu -->
        <template v-else>
            <button
                class="btn flex items-center text-left"
                @mouseenter="mouseOver"
                @mouseleave="mouseLeave"
                @click="isOpen = true"
            >
                <font-awesome-icon :icon="['fas', 'user']" class="mr-2.5 text-base" />
                <div class="text-left">
                    Ingelogd als: <br><span class="font-bold">{{ account?.fullname }}</span>
                </div>
            </button>

            <Transition>
                <div
                    v-if="isOpen"
                    class="absolute right-0 top-full z-20 mt-2 w-48 rounded-xl bg-white py-2 shadow-2xl ring-1 ring-black ring-opacity-5"
                    @mouseenter="mouseOver"
                    @mouseleave="mouseLeave"
                >
                    <NuxtLink
                        to="/account/instellingen"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                        Mijn account
                    </NuxtLink>
                    <button
                        class="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                        @click="handleLogoutClick"
                    >
                        Uitloggen
                    </button>
                </div>
            </Transition>
        </template>

        <!-- Login Modal -->
        <Teleport to="body">
            <div
                v-if="loginModalVisible"
                class="fixed inset-0 z-40 bg-black/10 backdrop-blur-sm"
                @click="handleBackdropClick"
            >
                <div class="flex min-h-screen items-center justify-center">
                    <div class="flex flex-col rounded-2xl bg-slate-100 drop-shadow-lg w-[450px] p-[40px] justify-between" @click.stop>
                        <AccountLogin
                            v-if="!isResettingPassword"
                            v-model:visible="loginModalVisible"
                            class="w-full max-w-md grow"
                            @login-success="onLoginSuccess"
                            @modal-close="onModalClose"
                        />
                        <AccountResetPassword
                            v-else
                            class="w-full max-w-md grow flex justify-center"
                            @modal-close="onModalClose"
                        />
                        <div class="flex justify-end pt-[40px]">
                            <button class="text-blue-demegro hover:underline hover:text-blue-demegro/80" @click="swapModalContent">
                                <template v-if="!isResettingPassword">
                                    Wachtwoord vergeten?
                                </template>
                                <template v-else>
                                    Terug naar inloggen
                                </template>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>

        <!-- Logout Confirmation Modal -->
        <Teleport to="body">
            <div
                v-if="logoutConfirmationVisible"
                class="fixed inset-0 z-50 flex items-center justify-center bg-black/10 p-4 backdrop-blur-sm"
            >
                <div class="w-full max-w-sm rounded-lg bg-white p-6 shadow-xl" @click.stop>
                    <h3 class="mb-4 text-lg font-semibold">
                        Uitloggen bevestigen
                    </h3>
                    <p class="mb-6 text-gray-600">
                        Weet u zeker dat u wilt uitloggen?
                    </p>
                    <div class="flex justify-end gap-3">
                        <button
                            class="px-4 py-2 text-gray-600 transition-colors duration-200 hover:text-gray-800"
                            @click="cancel()"
                        >
                            Annuleren
                        </button>
                        <button
                            class="rounded-lg bg-blue-demegro px-4 py-2 text-white transition-colors duration-200 hover:bg-dark-blue-demegro"
                            @click="confirm()"
                        >
                            Uitloggen
                        </button>
                    </div>
                </div>
            </div>
        </Teleport>
    </div>
</template>

<script setup lang="ts">
import { useConfirmDialog } from '@vueuse/core'

const { isPinging } = useLoginButton()
const loginModalStore = useLoginModalStore()

const authStore = useAuthStore()
const { authenticated, account } = storeToRefs(authStore)
const { handleLogout } = authStore

const isOpen = ref(false)
const isResettingPassword = ref(false)

// Login modal visibility
const loginModalVisible = computed({
    get: () => loginModalStore.isVisible,
    set: value => (value ? loginModalStore.loginModalShow() : loginModalStore.loginModalHide()),
})

// Logout confirmation dialog
const { reveal, confirm, cancel, isRevealed: logoutConfirmationVisible } = useConfirmDialog()

// Login modal handlers
function handleBackdropClick() {
    loginModalVisible.value = false
    isResettingPassword.value = false
}

function onModalClose() {
    loginModalVisible.value = false
    isResettingPassword.value = false
}

function onLoginSuccess() {
    loginModalVisible.value = false
}

function swapModalContent() {
    isResettingPassword.value = !isResettingPassword.value
}

// Logout handler
async function handleLogoutClick() {
    const { isCanceled } = await reveal()
    if (!isCanceled) {
        handleLogout()
    }
}
const isMouseOver = ref(false)

function mouseOver() {
    isOpen.value = true
    isMouseOver.value = true
}

function mouseLeave() {
    isMouseOver.value = false

    // Timer for main submenu
    setTimeout(() => {
        if (!isMouseOver.value) {
            isOpen.value = false
        }
    }, 500)
}
</script>
