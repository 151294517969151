<template>
    <div class="relative w-full">
        <!-- Search Input Group -->
        <div class="flex h-10">
            <input
                v-model="input"
                placeholder="Zoek door alle artikelen"
                class="h-full w-full content-center rounded-l-md bg-white pl-4 ring-1 ring-[#e7edef]"
                :class="{ 'ring-2 ring-blue-demegro': loading }"
                aria-label="Zoek in assortiment"
                @keydown.enter="handleRawSearch"
                @keydown.ctrl.enter.exact="handleRawSearchNewTab"
                @click="handleSearchSuggestions"
            >
            <button
                class="rounded-r-md bg-blue-demegro p-3 text-white ring-1 ring-blue-demegro"
                aria-label="Zoeken"
                @click="handleRawSearch"
            >
                <font-awesome-icon :icon="['faS', 'magnifying-glass']" class="text-[21px]" />
            </button>
        </div>

        <!-- Search Results Panel -->
        <div
            v-if="searchDropdownVisible"
            ref="searchDropdown"
            class="absolute right-0 top-full z-50 mt-2 max-h-[80vh] w-[600px] overflow-y-auto rounded-md bg-white shadow-lg ring-1 ring-gray-200"
        >
            <!-- Loading State -->
            <div v-if="loading" class="flex items-center justify-center p-4">
                <font-awesome-icon :icon="['faS', 'circle-notch']" class="animate-spin text-2xl text-blue-demegro" />
            </div>

            <!-- Results List -->
            <ul v-else-if="response" class="divide-y divide-gray-200">
                <li v-for="(producttype, index) in response" :key="index" class="group">
                    <!-- Producttype navigation link -->
                    <div class="flex items-center pl-3">
                        <div
                            class="flex flex-grow cursor-pointer items-center transition-colors hover:bg-gray-50"
                            @click="handleProducttypeSearch(response[index])"
                        >
                            <div class="mr-4 h-12 w-12 flex-shrink-0">
                                <DPicture :src="producttype.image?.src" />
                            </div>
                            <div class="p-3 text-gray-900">
                                <span class="font-medium">{{ producttype.name }}</span>
                                <span class="ml-2 text-sm text-gray-500">({{ producttype.count }} artikelen)</span>
                            </div>
                        </div>

                        <!-- Expand/Collapse Button -->
                        <button
                            class="p-3 text-gray-400 hover:text-gray-600"
                            :aria-expanded="isExpanded(index)"
                            :aria-label="isExpanded(index) ? 'Collapse' : 'Expand'"
                            @click="toggleGroup(index)"
                        >
                            <font-awesome-icon
                                :icon="['faS', isExpanded(index) ? 'chevron-up' : 'chevron-down']"
                                class="h-5 w-5"
                            />
                        </button>
                    </div>

                    <!-- Articles List -->
                    <div v-if="isExpanded(index) && producttype.articles" class="border-t border-gray-100">
                        <ul class="divide-y divide-gray-100">
                            <li v-for="article in producttype.articles" :key="article.id">
                                <NuxtLink
                                    :to="article.slug"
                                    class="block p-3 transition-colors hover:bg-gray-50"
                                    @click="resetSearch"
                                >
                                    <NavSearchbarArticle :article="article" />
                                </NuxtLink>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>

            <div v-if="status === 'success' && !response?.length" class="p-4 text-center text-gray-600">
                Geen resultaten gevonden
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { SearchResult } from '~/types/application'
// Types
import { onClickOutside, refDebounced } from '@vueuse/core'

// State
const input = ref('')
const searchQuery = refDebounced(input, 300)
const expandedTypes = ref<number[]>([])
const searchDropdownVisible = ref(false)
const searchStore = useSearchStore()

// Data Fetching
const {
    data: response,
    refresh,
    status,
    clear,
} = useAsyncData(
    'search-suggestion',
    async () => {
        if (!searchQuery.value?.trim()) {
            return
        }

        const response = await $fetch('/api/articles/searchsuggestion', {
            method: 'POST',
            body: {
                q: searchQuery.value,
            },
        })

        if (response) {
            if (!searchDropdownVisible.value) {
                dropdownOpen()
            }
            return response
        }

        return []
    },
    { watch: [searchQuery], immediate: false },
)

// Computed
const loading = computed(() => status.value === 'pending')

// Methods
function toggleGroup(producttypeIndex: number) {
    if (isExpanded(producttypeIndex)) {
        expandedTypes.value.splice(expandedTypes.value.indexOf(producttypeIndex), 1)
    } else {
        expandedTypes.value.push(producttypeIndex)
    }
}

function isExpanded(producttypeIndex: number) {
    return expandedTypes.value.includes(producttypeIndex)
}

// This function sets the Producttype and its articles in the searchStore so we can open it in the search result page.
function handleProducttypeSearch(searchResult: SearchResult) {
    searchStore.clear()
    searchStore.setSearch('producttype', searchResult.name, searchResult.articles)

    resetSearch()

    navigateTo({
        path: `/zoeken/producttype`,
        query: { q: searchStore.currentSearchQuery },
    })
}

// Perform a raw search based on the current input
function handleRawSearch() {
    if (searchStore.currentSearchQuery !== input.value) {
        searchStore.clear()
        searchStore.setSearch('query', input.value, null)
        resetSearch(false)
    } else {
        resetSearch()
    }

    navigateTo({
        path: '/zoeken/product',
        query: { q: searchStore.currentSearchQuery },
    })
}

function handleRawSearchNewTab() {
    resetSearch()

    const urlencodedquery = encodeURIComponent(searchStore.currentSearchQuery)
    navigateTo(`/zoeken/product?q=${urlencodedquery}`, {
        open: {
            target: '_blank',
        },
    })
}

// Open the dropdown and show search suggestions
function handleSearchSuggestions() {
    if (searchQuery.value?.trim()) {
        refresh()
        dropdownOpen()
    } else {
        dropdownClose()
    }
}

function resetSearch(clearData = true) {
    if (clearData) {
        clearNuxtData('searchPage')
    }
    clear()
    input.value = ''
    expandedTypes.value = []
    dropdownClose()
}

function dropdownClose() {
    searchDropdownVisible.value = false
    expandedTypes.value = []
}

function dropdownOpen() {
    searchDropdownVisible.value = true
}

const dropdown = useTemplateRef('searchDropdown')
onClickOutside(dropdown, () => {
    dropdownClose()
})
</script>
