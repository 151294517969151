<template>
    <div class="my-[14px] flex items-start space-x-6 px-[16px]">
        <!-- Image -->
        <div class="relative my-auto w-[83px]">
            <DPicture :src="image?.src" :alt="item.article.title" />
            <template
                v-if="
                    item.article.is_demedent
                        || item.article.manufacturer_name?.toUpperCase() === 'DEMEDENT'
                "
            >
                <NuxtImg
                    src="/no_risk_sticker.webp"
                    class="absolute -top-[10px] -right-[10px] z-10 size-8 rotate-[23deg]"
                />
            </template>
        </div>

        <!-- Info -->
        <div class="w-[60%]">
            <h4 class="font-bold">
                {{ item.article.title }}
            </h4>
            <p v-if="item.expiration" class="text-xs text-gray-demegro">
                <span class="font-bold text-red-500">Verkort houdbaar t/m <strong>{{ formatDate(item.expiration, 'expiration') }}</strong></span>
            </p>
            <p class="mt-1 text-xs/[18px] text-gray-demegro-light">
                <span>Verpakking: </span>
                <span class="text-gray-demegro">{{ item.article.packaging }}</span>
            </p>
            <p class="text-xs/[18px] text-gray-demegro-light">
                <span>Code: </span>
                <span
                    :class="{
                        'text-sm font-light italic text-gray-400': !item.article?.demegro_code,
                        'font-medium text-gray-700': item.article?.demegro_code,
                    }"
                >
                    {{ item.article?.demegro_code || 'Onbekend' }}
                </span>
            </p>
            <p class="text-xs/[18px] text-gray-demegro-light">
                <span>REF:</span>
                <span class="text-gray-demegro">{{ item.article.manufacturer_article_number }}</span>
            </p>
        </div>

        <!-- Price and Quantity -->
        <div class="w-[30%] text-right">
            <div class="flex flex-col items-end gap-1">
                <span class="text-base font-semibold">{{
                    item.article.price
                        ? `€ ${Number(item.article.price).toFixed(2).replace('.', ',')}`
                        : 'Prijs op aanvraag'
                }}</span>
                <span class="text-xs text-gray-demegro-light">
                    Aantal: <span class="text-gray-demegro">{{ item.amount }}</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { ClientorderItem } from '~/types/demegro'

const props = defineProps<{
    item: ClientorderItem
}>()

const image = computed(() => useArticle().getImage(props.item.article))

const { formatDate } = useUi()
</script>
