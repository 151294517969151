import type { MenuItem } from '~/types/application'
import type { Chapter } from '~/types/demegro'
import { defineStore } from 'pinia'

export const useMenuStore = defineStore('menu', () => {
    const authStore = useAuthStore()
    const chapterStore = useChapterStore()

    const baseMenuItems = ref<MenuItem[]>([
        {
            id: 1,
            label: 'Ons assortiment',
            path: '/assortiment',
            subItems: [],
        },
        { id: 2, label: 'Reparatie', path: '/reparatie' },
        { id: 3, label: 'Over ons', path: '/over-ons' },
        { id: 4, label: 'Contact', path: '/contact' },
    ])

    // Watch for chapter changes and update menu
    watchEffect(() => {
        if (!chapterStore.loading && chapterStore.chapters) {
            const productsMenuItem = baseMenuItems.value.find(item => item.id === 1)
            if (productsMenuItem) {
                productsMenuItem.subItems = [
                    {
                        id: 'nieuw',
                        label: 'Nieuw in assortiment',
                        path: '/assortiment/nieuw',
                        icon: ['far', 'sparkles'],
                    },
                    {
                        id: 'kort-houdbaar',
                        label: 'Kort houdbaar <span class="text-red-700 text-sm ">(UITVERKOOP)</span>',
                        path: '/assortiment/kort-houdbaar',
                        icon: ['far', 'calendar-clock'],
                    },
                    ...chapterStore.chapters.map((chapter: Chapter) => ({
                        id: chapter.id,
                        label: chapter.title,
                        path: chapter.slug,
                        subItems: chapter.categories.map(category => ({
                            id: category.id,
                            label: category.title,
                            path: category.slug,
                        })),
                    })),
                ]
            }
        }
    })

    const menuItems = computed(() => {
        const items = [...baseMenuItems.value]

        if (authStore.authenticated) {
            // items.push({
            //     id: 5,
            //     label: 'Mijn account',
            //     path: '/account',
            // })

            items.push({
                id: 'favorites',
                label: 'Uw voorkeursartikelen',
                path: '/assortiment/voorkeursartikelen',
                icon: ['far', 'star'],
            })
        }

        return items
    })

    return {
        menuItems,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useMenuStore, import.meta.hot))
}
